import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frontfooter',
  templateUrl: './frontfooter.component.html',
  styleUrls: ['./frontfooter.component.scss']
}) 
export class FrontfooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
 